//
import {
  createStyles,
  Container,
  Title,
  Text,
  Button,
  Group,
} from "@mantine/core";

import { useNavigate } from "react-router-dom";
const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  inner: {
    position: "relative",
  },

  image: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 0,
    opacity: 0.75,
  },

  content: {
    paddingTop: 220,
    position: "relative",
    zIndex: 1,

    [theme.fn.smallerThan("sm")]: {
      paddingTop: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 540,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

const BrokenPage = () => {
  const { classes } = useStyles();
  let navigate = useNavigate();
  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <img alt="" src="" className={classes.image} />
        <div className={classes.content}>
          <Title className={classes.title}>Nothing to see here</Title>
          <Text
            color="dimmed"
            size="lg"
            // align="center"
            className={classes.description}
          >
            THIS PAGE IS UNDER DEVELOPMENT OR YOU HAVE ENTERED AN INVALID URL.
          </Text>
          <Group position="center">
            <Button onClick={() => navigate(-1)} size="md">
              Take me back to the previous page
            </Button>
          </Group>
        </div>
      </div>
    </Container>
  );
};
export default BrokenPage;
